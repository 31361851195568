import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Fragment } from 'react';

export const Dropdown = ({
  items,
  onChange,
  value
}: {
  items: {
    id: string;
    label: string;
  }[];
  onChange: (item: { id: string; label: string }) => void;
  value: {
    id: string;
    label: string;
  };
}) => {
  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="relative flex max-w-xs items-center rounded-full focus:outline-none text-gradient-right font-medium text-xs">
          <span className="sr-only">Open menu</span>
          {value.label}

          <ChevronDownIcon className="ml-1 w-3 h-3 text-gradient-right" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none border border-gray-200">
          {items.map((item) => (
            <button
              key={item.label}
              onClick={() => {
                onChange(item);
              }}
              className={clsx(
                'block w-full px-4 py-2 text-x text-gray-700 text-left text-xs font-light',
                'hover:bg-gradient-right hover:bg-opacity-5 transition ease-in-out',
                item.id === value.id && 'bg-gradient-right bg-opacity-10'
              )}
            >
              <Menu.Item as="div">{item.label}</Menu.Item>
            </button>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
