import { Link, createBrowserRouter } from 'react-router-dom';

import { AppLayout } from '../components/AppLayout';
import { DashboardLayout, Home } from '../pages';
import { Logout } from '../pages/Logout';
import { Auth0ProviderWithNavigate, AuthenticationGuard, LoginCallback, Redirect404 } from '../components';
import { USERS_ROUTES } from './Users.routes';
import { SUBSCRIPTION_ROUTES } from './Subscription.routes';
import { PROFILE_SETTINGS_ROUTES } from './ProfileSettings.routes';
import { WORKFLOWS_ROUTES } from './Workflows.routes';
import { defaultHandle } from './utils.routes';

export const browserRouter = createBrowserRouter([
  {
    element: <Auth0ProviderWithNavigate />,
    children: [
      {
        path: '/',
        element: <AuthenticationGuard component={AppLayout} />,
        handle: {
          crumb: () => <Link to="/">Home</Link>
        },
        children: [
          {
            path: '/',
            element: <DashboardLayout />,
            handle: defaultHandle,
            children: [
              {
                index: true,
                element: <Home />,
                handle: defaultHandle
              },
              ...USERS_ROUTES,
              ...SUBSCRIPTION_ROUTES,
              ...PROFILE_SETTINGS_ROUTES,
              ...WORKFLOWS_ROUTES
            ]
          }
        ]
      },
      {
        path: 'logout',
        element: <Logout />,
        handle: defaultHandle
      },
      {
        path: '/login-callback',
        element: <LoginCallback />
      },
      {
        path: '*',
        element: <Redirect404 />
      }
    ]
  }
]);
