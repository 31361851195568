import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TagTypes } from './store.constants';
import { prepHeaders } from './utils';
import { ISODate, PaginatedResponse } from '../utils/types';

export type WorkflowInstance = {
  name: string;
  instanceId: string;
  createdTime: string;
  lastUpdatedTime: string;
  status: string;
  stepStatus: {
    [key: string]: {
      status: string;
      children?: string[];
    };
  };
};

export type Workflow = {
  id: string;
  name: string;
  description: string;
  triggers: any[];
  steps: any[];
  enabled: boolean;
  createdAt: ISODate;
  updatedAt: ISODate;
};

export const workflowsApi = createApi({
  reducerPath: 'workflows',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://api.uk.staging.fireflai.ai/app/workflow/portal/workflows`,
    prepareHeaders: prepHeaders
  }),
  tagTypes: [TagTypes.Workflows],
  endpoints: (builder) => ({
    // type must be defined, endpoint is ok
    getWorkflows: builder.query<Array<Workflow>, { searchTerm?: string; sortKey?: string }>({
      query: (options) => {
        return {
          url: `/`,
          params: {
            searchTerm: options.searchTerm,
            sortKey: options.sortKey
          },
          method: 'GET'
        };
      },
      providesTags: [TagTypes.Workflows]
    }),
    // type must be defined, endpoint is ok
    getWorkflow: builder.query<Workflow, string>({
      query: (workflowId: string) => {
        return {
          url: `/${workflowId}`,
          method: 'GET'
        };
      },
      providesTags: [TagTypes.Workflows]
    }),
    getMonitorRuns: builder.query<
      PaginatedResponse<Array<WorkflowInstance>>,
      { workflowId: string; page: number; perPage: number }
    >({
      query: (options) => {
        return {
          url: `/${options.workflowId}/runs`,
          method: 'GET',
          params: {
            page: options.page,
            perPage: options.perPage
          }
        };
      },
      providesTags: [TagTypes.Workflows]
    }),
    getMonitorRun: builder.query<WorkflowInstance, { workflowId: string; instanceId: string }>({
      query: (arg) => {
        const { workflowId, instanceId } = arg;
        return {
          url: `/${workflowId}/runs/${instanceId}`,
          method: 'GET'
        };
      },
      providesTags: [TagTypes.Workflows]
    })
  })
});

export const { useGetWorkflowsQuery, useGetWorkflowQuery, useGetMonitorRunQuery, useGetMonitorRunsQuery } =
  workflowsApi;
