import 'reactflow/dist/style.css';
import ReactFlow, { Controls, EdgeChange, NodeChange, addEdge, useEdgesState, useNodesState, Node } from 'reactflow';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Params } from '../Workflow.types';
import { useGetWorkflowQuery } from '../../../store/workflows';
import { LoadingSpinner } from '../../../components';
import { defaultPosition } from './WorkflowEditor.utils';
import { SelectedNode } from './SelectedNode';
import { generateFlow } from './Workflow.generator';
import { rsEdges as classificationEdges, rsNodes as classificationNodes } from './rsEmailClassificationWorkflow';
import { rsEdges as mdmEdges, rsNodes as mdmNodes } from './rsMdmWorkflow';

export const WorkflowEditor = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const { workflowId } = useParams<keyof Params>() as Params;
  const { isLoading, data: workflow } = useGetWorkflowQuery(workflowId);

  useEffect(() => {
    // hardcoded workflows for classification and mdm
    if (workflowId === '65fd7f9f3b5b6d9135bdc776') {
      setNodes(classificationNodes);
      setEdges(classificationEdges);
    } else if (workflowId === '65fd7e1e75e812aa4ff223f6' || workflowId === '65fd8b6976c3dd9c2869b97f') {
      setNodes(mdmNodes);
      setEdges(mdmEdges);
    } else {
      const { nodes: initialNodes, edges: initialEdges } = generateFlow({
        steps: workflow?.steps ?? [],
        nodes: [],
        edges: [],
        parentId: null,
        parentPosition: defaultPosition,
        level: 0
      });
      setNodes(initialNodes);
      setEdges(initialEdges);
    }
  }, [workflow, setEdges, setNodes, workflowId]);

  const [selectedNode, setSelectedNode] = useState<Node<any, string | undefined> | null>(null);
  const onConnect = useCallback((params: any) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  console.log(nodes);

  return (
    <div style={{ height: '600px' }} className="w-full relative">
      {isLoading && (
        <div className="flex w-full h-full items-center justify-center">
          <LoadingSpinner />
        </div>
      )}

      {!isLoading && (
        <>
          {selectedNode && <SelectedNode node={selectedNode} onClose={() => setSelectedNode(null)} />}
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={(changes: NodeChange[]) => {
              onNodesChange(changes);
            }}
            onEdgesChange={(changes: EdgeChange[]) => {
              onEdgesChange(changes);
            }}
            onNodeClick={(_, node) => {
              setSelectedNode(node);
            }}
            onConnect={onConnect}
            fitView
            attributionPosition="top-right"
          >
            <Controls />
          </ReactFlow>
        </>
      )}
    </div>
  );
};
