import { Disclosure, Menu, Transition } from '@headlessui/react';
import { UserIcon, XMarkIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { Fragment, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import fireflaiEmblem from '../../static/images/fireflai_emblem.png';
import { capitalise } from '../../utils';
import clsx from 'clsx';
import { useGetProfileDataQuery } from '../../store/profileData';
import { store } from '../../store/store';

const navigation = [
  { name: 'users', path: '/users/list', requiredPermissions: ['users:read'] },
  { name: 'subscription', path: '/subscription/usage', requiredPermissions: ['billing:read'] },
  { name: 'workflows', path: '/workflows/active', requiredPermissions: [] }
];
const userNavigation = [
  { name: 'Your Profile', path: '/profile' },
  { name: 'Sign out', path: '/logout' }
];

export const Navbar = () => {
  const location = useLocation();
  const state = store.getState();
  const userPermissions = state.auth.decodedToken?.permissions;

  const activeRoute = useMemo(() => {
    if (location.pathname === '/') return 'home';
    return location.pathname.split('/')[1];
  }, [location.pathname]);

  const { isLoading: isLoadingProfileData, data: profileData } = useGetProfileDataQuery();

  return (
    <Disclosure as="nav" className="bg-gradient-to-r from-gradient-left to-gradient-right font-inter">
      {({ open }) => (
        <>
          <div className="border-b border-gray-700">
            <div className="flex h-16 items-center justify-between px-3 md:px-8">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link to="/">
                    <img className="h-10 w-10 hover:animate-pulse" src={fireflaiEmblem} alt="Fireflai AI" />
                  </Link>
                </div>
                <div className="hidden md:block">
                  <div className="ml-8 flex items-baseline space-x-3">
                    {navigation.map((item) => {
                      if (
                        item.requiredPermissions.length > 0 &&
                        !item.requiredPermissions.every((permission) => userPermissions?.includes(permission))
                      )
                        return null;
                      return (
                        <Link
                          key={item.name}
                          to={item.path}
                          className={clsx(
                            activeRoute === item.name
                              ? 'text-white bg-white bg-opacity-10 rounded-3xl'
                              : 'text-navbar-text hover:bg-white hover:bg-opacity-10 rounded-3xl hover:text-white',
                            'rounded-3xl tracking-tight px-3 font-medium py-2 text-sm transition'
                          )}
                          aria-current={activeRoute === item.name ? 'page' : undefined}
                        >
                          {capitalise(item.name)}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="relative flex max-w-xs items-center rounded-full text-sm focus:outline-none ">
                        <span className="absolute" />
                        <span className="sr-only">Open user menu</span>

                        {!isLoadingProfileData && profileData && profileData.pictureUrl ? (
                          <img
                            className="h-8 w-8 rounded-full"
                            src={profileData.pictureUrl}
                            alt="Your profile Avatar"
                          />
                        ) : (
                          <UserIcon className="w-7 h-7 text-white rounded-full hover:text-fireflai-500 focus:text-fireflai-500" />
                        )}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            <Link
                              to={item.path}
                              className={clsx(
                                activeRoute === item.name ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              {capitalise(item.name)}
                            </Link>
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="flex md:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="border-b border-gray-700 md:hidden">
            <div className="space-y-1 px-2 py-3 sm:px-3">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className={clsx(
                    activeRoute === item.name
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={activeRoute === item.name ? 'page' : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="border-t border-gray-700 pb-3 pt-4">
              <div className="flex items-center px-5">
                {!isLoadingProfileData && profileData && profileData.pictureUrl ? (
                  <img className="h-8 w-8 rounded-full" src={profileData.pictureUrl} alt="Your profile Avatar" />
                ) : (
                  <div className="flex-shrink-0">
                    <UserIcon className="w-8 h-8 bg-violet-700 rounded-full text-white" />
                  </div>
                )}

                <div className="ml-3">
                  <div className="text-base font-medium leading-none text-white">{profileData?.name}</div>
                  <div className="text-sm font-medium leading-none text-gray-400">{profileData?.email}</div>
                </div>
              </div>
              <div className="mt-3 space-y-1 px-2">
                {userNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.path}
                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
