import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { LoadingSpinner } from '../Loading';

export const AuthenticationGuard = ({ component }: { component: React.ComponentType<object> }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="w-screen h-screen flex items-center justify-center">
        <LoadingSpinner />
      </div>
    )
  });

  return <Component />;
};
